import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = [
  [
    "/cs",
    "🇨🇿",
    "Čeština"
  ],
  [
    "/",
    "🇬🇧",
    "English"
  ]
]

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="pos--fix --spacing3 --size-M --transparent" type={"SiteHeader"} fixed={true} layout={"3"} transparent={true} langaugeSelector={true} social={{"twitter":"","facebook":"ferrariscuderiapraha","instagram":"ferrariscuderiapraha","youtube":""}} logo={{"src":"https://cdn.swbpg.com/o/40177/fa11ce9ebe8449fa8b04ae23dac6933e.png"}} logoMobile={{"src":"https://cdn.swbpg.com/o/40177/a702c8695b414959968b90b85fa2b9a3.png"}} title={{"color":"var(--black)","content":"22/9 - 25/9/2024","className":"fs--18 w--700 ls--002 lh--14"}} button={{"content":"","className":"btn-box"}} item={{"color":"var(--black)","className":"fs--18"}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}